const Config = {
  sidebarLevel: 3, // 侧边栏层级（无限级）限制, 默认为 3 级，可根据需求自行扩充
  openAutoJumpOut: true, // 是否开启无操作跳出
  defaultRoute: '/about', // 默认打开的路由
  websocketEnable: false, // 默认关闭 websocket
  showSidebarSearch: false, // 默认打开侧边栏搜索
  notLoginRoute: ['login'], // 无需登录即可访问的路由 name,
  useFrontEndErrorMsg: false, // 默认采用后端返回异常
  stagnateTime: 60 * 60 * 1000, // 无操作停滞时间，默认1小时
  baseURL: process.env.VUE_APP_BASE_URL, // API接口baseURL，在根目录.env文件查找对应环境变量配置
  mqttConfig: {
    // mqtt配置
    connectUrl: process.env.NODE_ENV === 'production' ? 'wss://iac.simon247.cn:8084/mqtt/' : 'ws://8.134.62.64:8083/mqtt/',
    options: {
      keepalive: 30, // 客户端心跳时间
      clean: true, // 是否以清除会话的方式建立连接
      connectTimeout: 4000, // 超时时间
      // reconnectPeriod: 0,
      clientId: `mqttjs_${Math.random().toString(16)}`,
      username: '',
      password: '',
    },
    subscribeTopic: 'iac/robot/#', // 后台订阅的消息主题
    physicalTopic: 'iac/robot/+/ring/physical', // 手环心率血压
    locationTopic: 'iac/robot/+/ring/location', // 手环位置状态
    heartbeatTopic: 'iac/robot/+/ring/heartbeat', // 手环心跳步数电量状态
    sosTopic: 'iac/robot/+/ring/sos', // 手环sos状态
    // movementTopic: 'iac/robot/+/server/movement', // 车体运动提醒消息
    sleepDetectorTopic: 'iac/robot/+/sleepDetector', // 睡眠监测数据采集
    chatCallTopic: 'iac/robot/+/chat/call', // 视频聊天呼叫消息
  },
  cameraConfig: {
    liveHost:
      process.env.NODE_ENV === 'production'
        ? 'https://iac.simon247.cn/live/live/test'
        : 'http://122.13.25.22:19980/live/test',
  },
}

export default Config
