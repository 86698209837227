const appUserRouter = {
  route: null,
  name: null,
  title: '用户管理',
  type: 'folder',
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/app-user/',
  order: null,
  inNav: true,
  permission: ['超级管理员独有权限'],
  children: [
    {
      title: '设备用户列表',
      type: 'view',
      name: 'appUserList',
      route: '/app-user-list',
      filePath: 'view/app-user/user-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      // permission: ['超级管理员独有权限'],
    },
    {
      title: '开通设备用户',
      type: 'view',
      inNav: true,
      route: '/app-user-add',
      icon: 'iconfont icon-add',
      name: 'appUserCreate',
      filePath: 'view/app-user/user-create.vue',
      // permission: ['超级管理员独有权限'],
    },
    {
      title: '设备用户档案',
      type: 'view',
      name: 'agedFileList',
      route: '/aged-file-list',
      filePath: 'view/app-user/aged-file-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      // permission: ['超级管理员独有权限'],
    },
    {
      title: '提醒事项管理',
      type: 'view',
      name: 'scheduleList',
      route: '/schedule-list',
      filePath: 'view/app-user/schedule-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      // permission: ['超级管理员独有权限'],
    },
    {
      title: '设备历史轨迹',
      type: 'view',
      name: 'deviceTrack',
      route: '/device-track',
      filePath: 'view/app-user/device-track.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
    },
    {
      title: '问诊医生',
      type: 'view',
      name: 'doctorList',
      route: '/doctor-list',
      filePath: 'view/app-user/doctor-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
    },
    {
      title: '用户家属',
      type: 'view',
      name: 'relativeList',
      route: '/relative-list',
      filePath: 'view/app-user/aged-relative-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
    },
    {
      title: '预订服务',
      type: 'view',
      name: 'reservationList',
      route: '/reservation-list',
      filePath: 'view/app-user/reservation-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
    },
    {
      title: '一周菜谱',
      type: 'view',
      name: 'weekRecipeList',
      route: '/weekRecipe-list',
      filePath: 'view/app-user/week-recipe-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
    },
  ],
}

export default appUserRouter
